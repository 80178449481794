import { EChartsOption } from 'echarts/types/dist/shared';
import { Opts, EChartsReactProps } from 'echarts-for-react/lib/types';
import React, { useEffect, useRef } from 'react';

import { lg } from 'utils/breakpoints';
import { ReactEChartsCore, echarts } from 'utils/echarts';
import { useWindowSize } from 'utils/hooks';
import { grid, gridMobile } from 'utils/plots/plot-config';

const BasePlot: React.FC<
  {
    option: EChartsOption;
    onEventsZRenderer?: { (dateAtClick: Date): void }[];
  } & Omit<EChartsReactProps, 'option'>
> = ({ option, onEvents, onEventsZRenderer = [], ...props }) => {
  const echartsRef = useRef<ReactEChartsCore>(null);

  // If we want to handle a click in the plot that isn't on any element, i.e. on the plot's background or empty space,
  // We have to use use the echarts zRenderer event handling system, instead of the onEvents prop.
  // Here we return the date at the x axis when clicking on the plot's background.
  useEffect(() => {
    const echartsInstance = echartsRef.current?.getEchartsInstance();
    if (echartsInstance === null || !echartsInstance) return;
    if (echartsInstance.getZr() === null || undefined) return;

    echartsInstance.getZr().on('click', (params: any) => {
      //Target is undefined when not clicking any element in the plot
      if (params.target === undefined) {
        //The value at the x axis axisPointer is a unix timestamp
        const pointInPixel = [params.offsetX, params.offsetY];
        const pointInGrid = echartsInstance.convertFromPixel({ seriesIndex: 0 }, pointInPixel);
        if (pointInGrid) {
          const dateAtClick = new Date(pointInGrid[0]);
          onEventsZRenderer?.forEach(callback => {
            callback(dateAtClick);
          });
        }
      }
    });
  }, [echartsRef, onEventsZRenderer]);

  const [width] = useWindowSize();
  // Default grid
  if (!option.grid) {
    option.grid = width > lg ? grid : gridMobile;
  }

  // Default colors
  if (!option.color) {
    option.color = [
      '#c23531',
      '#2f4554',
      '#61a0a8',
      '#d48265',
      '#91c7ae',
      '#749f83',
      '#ca8622',
      '#bda29a',
      '#6e7074',
      '#546570',
      '#c4ccd3',
    ];
  }

  return (
    <ReactEChartsCore
      ref={echartsRef}
      echarts={echarts}
      option={option}
      opts={
        {
          renderer: 'canvas',
        } as Opts
      }
      onEvents={onEvents}
      {...props}
    />
  );
};

export default BasePlot;
