import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePickerInput } from 'components';
import Modal from 'components/modals/Modal';

export const DatePickerInputModal: React.FC<{
    onSelect: (dates: { start: Date; end?: Date }) => void;
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    setupCompletedDate?: Date;
    entireHistoryDate?: Date;
    latestActiveDate?: Date;
    showPredefinedRanges?: boolean;
    initialStartDate?: Date;
    initialEndDate?: Date;
    maxMonthsBack?: number;
}> = ({ show, setShow, initialStartDate, initialEndDate, ...props }) => {
    const { t } = useTranslation('components');

    return (
        <Modal size="sm" title={t('modals.DatePickerModal.title')} show={show} setShow={setShow}>
            <DatePickerInput
                key={`${initialStartDate}-${initialEndDate}`}
                initialStartDate={initialStartDate}
                initialEndDate={initialEndDate}
                showPredefinedRanges
                inline={true}
                {...props}
            />
        </Modal>
    );
};
