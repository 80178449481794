import * as yup from 'yup';

import { SubmittingButton, FormTextareaInput } from 'components';
import Form from 'components/forms/Form';

const SingleTextareaForm: React.FC<{
  initialValue?: string;
  onSubmit: ({ text }: { text: string }) => Promise<void>;
  resetFormAfterSubmission?: boolean;
  buttonText: string;
  placeholder: string;
  lengthError?: string;
  requiredError?: string;
  elementBeforeSubmitButton?: JSX.Element;
  maxLength?: number;
  autoFocus?: boolean;
  onTextAreaChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}> = ({
  initialValue,
  onSubmit,
  resetFormAfterSubmission,
  buttonText,
  placeholder,
  lengthError,
  requiredError,
  elementBeforeSubmitButton,
  autoFocus = false,
  onTextAreaChange = () => {},
  ...props
}) => {
  const schemaObj = {
    text: yup.string().min(1, lengthError).required(requiredError),
  };

  const handleKeyboardSubmit = (event: React.KeyboardEvent<HTMLFormElement>) => {
    const formElement = event.currentTarget;
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      formElement.requestSubmit();
    }
  };

  return (
    <Form
      schemaObj={schemaObj}
      resetFormAfterSubmission={resetFormAfterSubmission}
      onSubmit={async values => await onSubmit(values)}
      values={{
        text: initialValue || '',
      }}
      body={({ handleSubmit, isSubmitting }) => (
        <form noValidate onSubmit={handleSubmit} onKeyDown={e => handleKeyboardSubmit(e)}>
          <FormTextareaInput
            name="text"
            placeholder={placeholder}
            autoFocus={autoFocus}
            onChange={onTextAreaChange}
            {...props}
          />
          {elementBeforeSubmitButton}
          <SubmittingButton buttonText={buttonText} submitting={isSubmitting} />
        </form>
      )}
    />
  );
};

export default SingleTextareaForm;
