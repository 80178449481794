import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type CustomHeaderProps = {
  date: Date;
  minDate: Date | undefined;
  maxDate: Date | undefined;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
};

export const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  minDate,
  maxDate,
}: CustomHeaderProps) => {
  const { t } = useTranslation('components');

  const monthLabels: { [key: number]: string } = {
    0: t('inputs.DatePickerInput.months.January'),
    1: t('inputs.DatePickerInput.months.February'),
    2: t('inputs.DatePickerInput.months.March'),
    3: t('inputs.DatePickerInput.months.April'),
    4: t('inputs.DatePickerInput.months.May'),
    5: t('inputs.DatePickerInput.months.June'),
    6: t('inputs.DatePickerInput.months.July'),
    7: t('inputs.DatePickerInput.months.August'),
    8: t('inputs.DatePickerInput.months.September'),
    9: t('inputs.DatePickerInput.months.October'),
    10: t('inputs.DatePickerInput.months.November'),
    11: t('inputs.DatePickerInput.months.December'),
  };

  const isPrevMonthDisabled = () => {
    if (!minDate) return false;
    const prevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    return prevMonth < minDate;
  };

  const isNextMonthDisabled = () => {
    if (!maxDate) return false;
    const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    return nextMonth > maxDate;
  };

  const prevMonthDisabled = prevMonthButtonDisabled || isPrevMonthDisabled();
  const nextMonthDisabled = nextMonthButtonDisabled || isNextMonthDisabled();

  return (
    <div className="flex flex-row justify-between p-2">
      <button onClick={decreaseMonth} disabled={prevMonthDisabled}>
        <FontAwesomeIcon
          icon={faChevronCircleLeft}
          className={classNames({
            'text-brand-gray': !prevMonthDisabled,
            'text-brand-gray-light-2': prevMonthDisabled,
          })}
        />
      </button>
      <span className="text-sm text-brand-gray font-bold">
        {monthLabels[date.getMonth()]} {date.getFullYear()}
      </span>
      <button onClick={increaseMonth} disabled={nextMonthDisabled}>
        <FontAwesomeIcon
          icon={faChevronCircleRight}
          className={classNames({
            'text-brand-gray': !nextMonthDisabled,
            'text-brand-gray-light-2': nextMonthDisabled,
          })}
        />
      </button>
    </div>
  );
};
