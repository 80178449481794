import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';

export const useSensorsBulkAlertRules = (
  sensorIds?: string[],
  alertRuleIds?: string[],
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = ['useSensorsBulkAlertRules', sensorIds, alertRuleIds];

  const {
    data: alertRuleIdToSensorIdToIsEnabled,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.post('/sensors_bulk/alert_rules/enabled', {
        sensor_ids: sensorIds,
        alert_rule_ids: alertRuleIds,
      }),
    select: (alertRuleIdToSensorIdToIsEnabledRaw: any) =>
      alertRuleIdToSensorIdToIsEnabledRaw as {
        [sensorId: string]: { [alertRuleId: string]: boolean };
      },
    enabled:
      enableGet && sensorIds && sensorIds.length > 0 && alertRuleIds && alertRuleIds.length > 0,
  });

  return {
    alertRuleIdToSensorIdToIsEnabled,
    isPending: isPending || isLoading || isFetching,
  };
};
