import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Badge } from 'components/badges';
import { faSquirtyGroup } from 'components/icons';

interface Props {
  blueprintTitle: string;
  blueprintTags: string;
  blueprintGroupId: string;
  blueprintGroup: string;
}

export const ActionBarHeader: React.FC<Props> = ({
  blueprintTitle,
  blueprintTags,
  blueprintGroupId,
  blueprintGroup,
}) => {
  const { t } = useTranslation('components');
  const location = useLocation();
  const scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-start gap-2 items-center text-xl font-bold">
        {blueprintTitle}
        {blueprintTags
          .split(',')
          .map((tag: string) => tag.trim())
          .filter((tag: string) => tag.length > 0)
          .map((tag: string, key: number) => (
            <Badge
              key={key}
              className="bg-blue-500 text-white inline-block hidden md:block"
              size="xs"
            >
              {tag}
            </Badge>
          ))}
      </div>
      <div className="flex items-center text-sm font-light text-brand-beige-light-1 hover:text-brand-beige-light-2">
        {blueprintGroup && (
          <Link to={`/user/groups/${blueprintGroupId}`} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className="mr-1" icon={faSquirtyGroup} />
            {blueprintGroup}
          </Link>
        )}
        {!blueprintGroup && !location.pathname.includes('sensors') && (
          <div
            className="flex items-center gap-1 text-brand-orange hover:cursor-pointer hover:text-brand-orange-light-1"
            onClick={scrollToBottom}
          >
            <FontAwesomeIcon className="mr-1" icon={faSquirtyGroup} />
            <div className="text-xs">{t('blueprints.ActionBar.Header.addToGroup')}</div>
          </div>
        )}
      </div>
    </div>
  );
};
