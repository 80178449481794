import { HTMLAttributes, useState } from 'react';

import {
  BlueprintCanvasContext,
  Canvas,
  PlayableTimeline,
  SensorsCarousel,
} from 'components/BlueprintCanvas/components';
import { BlueprintPosition } from 'utils/types';

export const BlueprintCanvas: React.FC<
  {
    blueprintId: string;
    stageHeight?: number;
    onlyEnableSensorIds?: string[];
    onlyShowSensorIds?: string[];
    onlyShowTooltipforSensorIds?: string[];
    showSensors?: boolean;
    showHeader?: boolean;
    showPlayableTimeline?: boolean;
    enableWheelScrolling?: boolean;
    enableDragging?: boolean;
    showToolbox?: boolean;
    playTimelapseOnMount?: boolean;
    loopTimelapse?: boolean;
    editModeEnabled?: boolean;
    setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  } & HTMLAttributes<HTMLDivElement>
> = ({
  blueprintId,
  stageHeight,
  onlyEnableSensorIds,
  onlyShowSensorIds,
  onlyShowTooltipforSensorIds,
  showHeader = false,
  showSensors = false,
  showPlayableTimeline = false,
  enableWheelScrolling = false,
  enableDragging = false,
  showToolbox = false,
  playTimelapseOnMount = false,
  loopTimelapse = false,
  className = 'shadow mb-3',
  editModeEnabled = false,
  setEditMode = () => {},
  ...props
}) => {
  // States
  const [hours, setHours] = useState(0);
  const [lockSensors, setLockSensors] = useState(true);
  const [activeBlueprintPosition, setActiveBlueprintPosition] = useState<BlueprintPosition>();

  return (
    <BlueprintCanvasContext.Provider
      value={{
        hours,
        setHours,
        lockSensors,
        setLockSensors,
        activeBlueprintPosition,
        setActiveBlueprintPosition,
        editModeEnabled,
        setEditMode,
      }}
    >
      <div className={className} {...props}>
        <Canvas
          blueprintId={blueprintId}
          stageHeight={stageHeight}
          onlyEnableSensorIds={onlyEnableSensorIds}
          onlyShowSensorIds={onlyShowSensorIds}
          onlyShowTooltipforSensorIds={onlyShowTooltipforSensorIds}
          enableWheelScrolling={enableWheelScrolling}
          enableDragging={enableDragging}
          enableToolbox={showToolbox}
        />
      </div>
      <div className="px-6 lg:px-7">
        <PlayableTimeline
          blueprintId={blueprintId}
          show={showPlayableTimeline && lockSensors}
          playOnMount={playTimelapseOnMount}
          loop={loopTimelapse}
        />
      </div>
      {editModeEnabled && (
        <SensorsCarousel blueprintId={blueprintId} onlyEnableSensorIds={onlyEnableSensorIds} />
      )}
    </BlueprintCanvasContext.Provider>
  );
};
