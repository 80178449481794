import { faEdit, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Dispatch, SetStateAction } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { NoteModalMode } from 'components/notes/NotesModal';
import { brandLime, brandLimeTint1, brandOrange, brandOrangeLight3 } from 'utils/colors';
import { TailwindLeftPadString, TailwindWidthString } from 'utils/types';
import 'styles/components/_toast.scss';

const SUCCESS_GRADIENT = `linear-gradient(90deg, ${brandLime}, ${brandLimeTint1})`;
const ALERT_GRADIENT = `linear-gradient(90deg, ${brandOrange}, ${brandOrangeLight3}`;

type toastBackgroundGradient = 'success' | 'alert';

interface NoteToastProps {
  setNotesModalMode: Dispatch<SetStateAction<NoteModalMode>>;
  setShowNotesModal: Dispatch<SetStateAction<boolean>>;
  backgroundGradient: toastBackgroundGradient;
  noteAction: NoteModalMode;
}

export const createNoteToast = (
  noteAction: NoteModalMode,
  setActiveToast: Dispatch<SetStateAction<string | undefined>>,
  setNotesModalMode: Dispatch<SetStateAction<NoteModalMode>>,
  setShowNotesModal: Dispatch<SetStateAction<boolean>>,
) => {
  if (noteAction === 'delete_previous') {
    setActiveToast(
      toast(
        <NoteToast
          setNotesModalMode={setNotesModalMode}
          setShowNotesModal={setShowNotesModal}
          backgroundGradient={'alert'}
          noteAction={noteAction}
        />,
        { duration: 5000 },
      ),
    );
  } else if (noteAction === 'create') {
    setActiveToast(
      toast(
        <NoteToast
          setNotesModalMode={setNotesModalMode}
          setShowNotesModal={setShowNotesModal}
          backgroundGradient={'success'}
          noteAction={noteAction}
        />,
        { duration: 15000 },
      ),
    );
  } else {
    setActiveToast(
      toast(
        <NoteToast
          setNotesModalMode={setNotesModalMode}
          setShowNotesModal={setShowNotesModal}
          backgroundGradient={'success'}
          noteAction={noteAction}
        />,
        { duration: 5000 },
      ),
    );
  }
};

export const NoteToast: React.FC<NoteToastProps> = ({
  setNotesModalMode,
  setShowNotesModal,
  backgroundGradient,
  noteAction,
}) => {
  const { t } = useTranslation('components');

  let text = '';
  let width: TailwindWidthString = 'w-48';
  let textLeftPadding: TailwindLeftPadString = 'pl-3';

  switch (noteAction) {
    case 'create':
      text = t('notesModal.noteAdded');
      break;
    case 'edit':
      text = t('notesModal.noteEdited');
      width = 'w-36';
      textLeftPadding = 'pl-0';
      break;
    case 'delete_previous':
      text = t('notesModal.noteDeleted');
      width = 'w-36';
      textLeftPadding = 'pl-0';
      break;
    default:
      text = t('notesModal.noteAdded');
  }

  return (
    <div className={classNames('absolute bottom-2 h-8 h-8 rounded-full overflow-hidden ', width)}>
      <div
        id="toastBackgroundWrapoer"
        className={classNames('w-[200%] h-8')}
        style={{
          backgroundImage: backgroundGradient === 'success' ? SUCCESS_GRADIENT : ALERT_GRADIENT,
          animation: 'toast-background-slide 3s infinite',
          animationTimingFunction: 'ease-in-out',
        }}
      ></div>
      <div
        id="toastText"
        className={classNames('absolute top-0 left-0 h-8 flex items-center justify-center', width)}
      >
        <div
          className={classNames('flex flex-row text-brand-gray items-center justify-right', width)}
        >
          <p
            className={classNames(
              'grow text-brand-gray flex items-center justify-center',
              textLeftPadding,
            )}
          >
            {text}
          </p>
          {noteAction === 'create' && (
            <div className="flex flex-row">
              <button
                className="flex items-center justify-center hover:text-brand-gray-light-1 px-2"
                onClick={() => {
                  toast.dismiss();
                  setNotesModalMode('edit');
                  setShowNotesModal(true);
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button
                className="flex items-center justify-center hover:text-brand-gray-light-1 px-2"
                onClick={() => {
                  toast.dismiss();
                  setNotesModalMode('delete_previous');
                }}
              >
                <FontAwesomeIcon icon={faUndo} />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
