import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmittingButton } from 'components/buttons';
import { CustomerOrUserSelectInput } from 'components/inputs';
import Modal from 'components/modals/Modal';
import { useCurrentUser } from 'utils/hooks';
import { useUserUsers } from 'utils/hooks/data';

export const AssignResponsibleModal: React.FC<{
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  onSubmit: (userId: string) => Promise<void>;
}> = ({ show, setShow, onSubmit }) => {
  const { t } = useTranslation('components');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responsibleUserId, setResponsibleUserId] = useState<string | null>(null);

  const { user } = useCurrentUser();
  const { users, isPending } = useUserUsers(user?.id);

  return (
    <Modal
      title={t('components:modals.AssignResponsibleModal.title')}
      show={show}
      setShow={setShow}
      onClick={e => e.stopPropagation()}
    >
      <div className="flex flex-col gap-y-1 mb-4 items-start w-full">
        <CustomerOrUserSelectInput
          className="flex w-full"
          onSelect={(customerOrUser, _) => {
            setResponsibleUserId(customerOrUser.id);
          }}
          usersToSelect={users}
          isPending={isPending}
          placeholder={t('components:modals.AssignResponsibleModal.searchUser')}
        />
      </div>

      <SubmittingButton
        onClick={async () => {
          setIsSubmitting(true);
          try {
            if (responsibleUserId) {
              await onSubmit(responsibleUserId);
            }
          } catch (error) {}
          setIsSubmitting(false);
        }}
        submitting={isSubmitting}
        disabled={!responsibleUserId || isSubmitting}
        buttonText={t('components:modals.AssignResponsibleModal.assignButtonText')}
      />
    </Modal>
  );
};
