import classNames from 'classnames';
import { useContext } from 'react';

import { SensorsAlertRulesMatrixContext } from 'components/SensorsAlertRulesMatrix/components/SensorsAlertRulesMatrixContext';
import AlarmRule from 'utils/types/AlarmRule';

export const HeaderAlertRuleColumn: React.FC<{
  columnIndex: number;
  alertRule: AlarmRule;
}> = ({ columnIndex, alertRule }) => {
  const { hoveringColumnIndex, setHoveringColumnIndex, setHoveringRowIndex } = useContext(
    SensorsAlertRulesMatrixContext,
  );

  return (
    <div
      className={classNames('w-6 py-1', {
        'bg-brand-lime': hoveringColumnIndex === columnIndex,
      })}
      onMouseEnter={() => {
        setHoveringRowIndex(-1);
        setHoveringColumnIndex(columnIndex);
      }}
      data-tooltip-id="route-tooltip"
      data-tooltip-content={alertRule.name}
    >
      <p className="[writing-mode:vertical-lr] font-bold px-1">{alertRule.name}</p>
    </div>
  );
};
