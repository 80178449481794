import React, { Dispatch, SetStateAction } from 'react';

export type SensorsAlertRulesMatrixContextProps = {
  isPending?: boolean;
  hoveringRowIndex: number;
  hoveringColumnIndex: number;
  setHoveringRowIndex: Dispatch<SetStateAction<number>>;
  setHoveringColumnIndex: Dispatch<SetStateAction<number>>;
  setIsMutating: Dispatch<SetStateAction<boolean>>;
  isAlertRuleEnabledForSensor: (sensorId: string, alertRuleId: string) => boolean;
  areAllAlertRulesEnabledForSensor: (sensorId: string) => boolean;
  isAlertRuleEnabledForAllSensors: (alertRuleId: string) => boolean;
};

export const SensorsAlertRulesMatrixContext =
  React.createContext<SensorsAlertRulesMatrixContextProps>({
    isPending: false,
    hoveringRowIndex: -1,
    hoveringColumnIndex: -1,
    setHoveringRowIndex: () => {},
    setHoveringColumnIndex: () => {},
    isAlertRuleEnabledForSensor: () => false,
    setIsMutating: () => {},
    areAllAlertRulesEnabledForSensor: () => false,
    isAlertRuleEnabledForAllSensors: () => false,
  });
