import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AssignResponsibleModal } from 'components/modals';
import { useAlarm } from 'utils/hooks/data';

export const AssignResponsibleButton = ({ alertId }: { alertId: string }) => {
  const { t } = useTranslation('components');

  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

  const { alarm, assignResponsible } = useAlarm(alertId);

  const isAlreadyAssigned = !!alarm?.responsible_user;

  return (
    <>
      <button
        className={classNames('border rounded-lg p-2 border-brand-green text-brand-green', {
          'inset-0 bg-brand-gray-light-4 opacity-60': isAlreadyAssigned,
        })}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();

          setIsAssignModalOpen(true);
        }}
        disabled={isAlreadyAssigned}
      >
        {isAlreadyAssigned ? (
          <div className="flex items-center gap-1">
            <span>{t('buttons.AssignResponsibleButton.assigned')}</span>
            <FontAwesomeIcon icon={faCheck} />
          </div>
        ) : (
          <>{t('buttons.AssignResponsibleButton.assign')}</>
        )}
      </button>
      <AssignResponsibleModal
        show={isAssignModalOpen}
        setShow={setIsAssignModalOpen}
        onSubmit={async (userId: string) => {
          await assignResponsible(userId);
          setIsAssignModalOpen(false);
        }}
      />
    </>
  );
};
