import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SettingsDropdown } from 'components/dropdowns';
import { EditBlueprintModal } from 'components/modals';
import { useBlueprint } from 'utils/hooks/data';
import { notificationSuccess, notificationWarningConfirm } from 'utils/notifications';

export const BlueprintSettingsDropdown: React.FC<{
  blueprintId: string;
  onDeleteRedirectPath?: String | undefined;
}> = ({ blueprintId, onDeleteRedirectPath }) => {
  const { t } = useTranslation('components');
  const [showEditModal, setShowEditModal] = useState(false);

  const navigate = useNavigate();

  const { blueprint, deleteBlueprint, updateBlueprint } = useBlueprint(blueprintId);

  const onDeleteBlueprint = async () => {
    const confirmed = await notificationWarningConfirm();
    if (!confirmed) return;

    await deleteBlueprint();
    notificationSuccess(t('dropdowns.BlueprintDropdownSettings.onDeleteBlueprint.successText'));

    if (onDeleteRedirectPath !== undefined) {
      navigate(`/${onDeleteRedirectPath}`);
    }
  };

  const handleEdit = () => {
    setShowEditModal(true);
  };

  return (
    <>
      <SettingsDropdown
        size="lg"
        onEdit={handleEdit}
        onDelete={onDeleteBlueprint}
        pinToTopRight={false}
        containerWidth="w-7"
        containerHeight="h-7"
      ></SettingsDropdown>
      {blueprint && (
        <EditBlueprintModal
          blueprint={blueprint}
          updateBlueprint={updateBlueprint}
          show={showEditModal}
          setShow={setShowEditModal}
        />
      )}
    </>
  );
};
