import orderBy from 'lodash/orderBy';
import { useState } from 'react';

import {
  SensorAlertRulesRow,
  HeaderAlertRulesRow,
  SensorsAlertRulesMatrixContext,
} from 'components/SensorsAlertRulesMatrix/components';
import { useSensorsBulkAlertRules } from 'utils/hooks/data';
import AlarmRule from 'utils/types/AlarmRule';
import Sensor from 'utils/types/Sensor';

export const SensorsAlertRulesMatrix: React.FC<{
  sensors?: Sensor[];
  alertRules?: AlarmRule[];
}> = ({ sensors, alertRules }) => {
  const [isMutating, setIsMutating] = useState(false);
  const [hoveringRowIndex, setHoveringRowIndex] = useState(-1);
  const [hoveringColumnIndex, setHoveringColumnIndex] = useState(-1);

  const { alertRuleIdToSensorIdToIsEnabled, isPending } = useSensorsBulkAlertRules(
    sensors?.map(sensor => sensor.id),
    alertRules?.map(alertRule => alertRule.id),
  );

  const isAlertRuleEnabledForSensor = (sensorId: string, alertRuleId: string) =>
    alertRuleIdToSensorIdToIsEnabled?.[sensorId]?.[alertRuleId] ?? false;
  const areAllAlertRulesEnabledForSensor = (sensorId: string) =>
    (alertRules || [])
      .map(alertRule => alertRule.id)
      .every(alertRuleId => isAlertRuleEnabledForSensor(sensorId, alertRuleId));
  const isAlertRuleEnabledForAllSensors = (alertRuleId: string) =>
    (sensors || [])
      .map(sensor => sensor.id)
      .every(sensorId => isAlertRuleEnabledForSensor(sensorId, alertRuleId));

  return (
    <div
      className="flex justify-center"
      onMouseLeave={() => {
        setHoveringRowIndex(-1);
        setHoveringColumnIndex(-1);
      }}
    >
      <div>
        <SensorsAlertRulesMatrixContext.Provider
          value={{
            setIsMutating,
            isPending: isPending || isMutating,
            hoveringRowIndex,
            hoveringColumnIndex,
            setHoveringRowIndex,
            setHoveringColumnIndex,
            isAlertRuleEnabledForSensor,
            areAllAlertRulesEnabledForSensor,
            isAlertRuleEnabledForAllSensors,
          }}
        >
          <HeaderAlertRulesRow alertRules={alertRules} />
          {orderBy(sensors, 'name').map((sensor, index) => (
            <SensorAlertRulesRow
              key={sensor.id}
              rowIndex={index}
              sensor={sensor}
              alertRules={alertRules}
            />
          ))}
        </SensorsAlertRulesMatrixContext.Provider>
      </div>
    </div>
  );
};
