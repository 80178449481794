import { useTranslation } from 'react-i18next';

import UsersInlineList from 'components/UsersInlineList';
import User from 'utils/types/User';

export const SeenBy = ({ users }: { users?: User[] }) => {
  const { t } = useTranslation('components');

  return (
    <>
      {(users ?? []).length > 0 && (
        <div className="flex flex-row gap-1 items-center">
          <span className="text-brand-gray-light-1 text-xs">
            {t('components:Alerts.AlertActionsStatus.seenBy')}
          </span>
          <UsersInlineList
            users={users}
            size="md"
            data-tooltip-content={`${t('components:Alerts.AlertActionsStatus.seenBy')} ${users ? users?.map(user => user.full_name).join(', ') : ''}`}
            data-tooltip-id="route-tooltip"
          />
        </div>
      )}
    </>
  );
};
