import { SensorsAlertRulesMatrix } from 'components/SensorsAlertRulesMatrix';
import AlarmRule from 'utils/types/AlarmRule';
import Sensor from 'utils/types/Sensor';

export const SensorAlertRulesMatrix: React.FC<{
  sensor?: Sensor;
  alertRules?: AlarmRule[];
}> = ({ sensor, alertRules }) => (
  <SensorsAlertRulesMatrix sensors={sensor ? [sensor] : undefined} alertRules={alertRules} />
);
