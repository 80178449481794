import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseAlarm } from 'utils/types/Alarm';
import HookOptions from 'utils/types/HookOptions';

type Options = HookOptions & {
  seenOnly?: boolean;
  unseenOnly?: boolean;
  resolvedOnly?: boolean;
  unresolvedOnly?: boolean;
};

export const useCustomerAlerts = (
  customerId?: string,
  { enableGet = true, seenOnly, unseenOnly, resolvedOnly, unresolvedOnly }: Options = {},
) => {
  const queryKey = [
    'useCustomerAlerts',
    customerId,
    seenOnly,
    unseenOnly,
    resolvedOnly,
    unresolvedOnly,
  ];

  // Get
  const { data: alerts, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/customers/${customerId}/alarms`, {
        params: {
          seen_only: seenOnly,
          unseen_only: unseenOnly,
          resolved_only: resolvedOnly,
          unresolved_only: unresolvedOnly,
        },
      }),
    select: (alarms: any[]) => alarms.map(alarm => parseAlarm(alarm)),
    enabled: enableGet && !!customerId,
  });

  return { alerts, isPending };
};
