import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isSameDay } from 'date-fns';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import {
  CustomHeader,
  PredefinedRange,
  PredefinedRanges
} from 'components/inputs/DatePickerInput/components';
import 'styles/components/_date-picker.scss';
import { useOnClickOutside } from 'utils/hooks';

export type DatePickerInputProps = {
  onSelect: (dates: { start: Date; end?: Date }) => void;
  initialStartDate?: Date;
  initialEndDate?: Date;
  className?: string;
  maxDate?: Date;
  maxMonthsBack?: number;
  inline?: boolean;
  showPredefinedRanges?: boolean;
  openDatePickerCalendar?: boolean;
  setOpenDatePickerCalendar?: Dispatch<SetStateAction<boolean>>;
  singleDate?: boolean;
  isDisabled?: boolean;
  setupCompletedDate?: Date;
  entireHistoryDate?: Date;
  latestActiveDate?: Date;
};

export const DatePickerInput = ({
  onSelect,
  initialStartDate,
  initialEndDate,
  className,
  maxDate,
  maxMonthsBack = 48,
  inline = false,
  showPredefinedRanges = false,
  openDatePickerCalendar = false,
  setOpenDatePickerCalendar = () => { },
  singleDate = false,
  isDisabled = false,
  ...props
}: DatePickerInputProps) => {
  const { t } = useTranslation('components');

  const ref = useRef<HTMLDivElement>(null);
  const hoveredDateRef = useRef<Date | null>(null);

  const [startDate, setStartDate] = useState<Date | undefined>(initialStartDate);
  const [endDate, setEndDate] = useState<Date | undefined>(initialEndDate);

  const [selectedPredefinedRange, setSelectedPredefinedRange] = useState<
    PredefinedRange | undefined
  >();
  const [daysUpToToday, setDaysUpToToday] = useState<string>('');

  const now = new Date();

  const minDate =
    maxMonthsBack !== undefined
      ? new Date(now.getFullYear(), now.getMonth() - maxMonthsBack, now.getDate())
      : undefined;

  const dayLabels: { [key: string]: string } = {
    Monday: t('inputs.DatePickerInput.days.Mon'),
    Tuesday: t('inputs.DatePickerInput.days.Tue'),
    Wednesday: t('inputs.DatePickerInput.days.Wed'),
    Thursday: t('inputs.DatePickerInput.days.Thu'),
    Friday: t('inputs.DatePickerInput.days.Fri'),
    Saturday: t('inputs.DatePickerInput.days.Sat'),
    Sunday: t('inputs.DatePickerInput.days.Sun'),
  };

  const onChange = (dates: Date | [Date | null, Date | null] | null) => {
    if (singleDate) {
      const date = dates as Date | null;
      setStartDate(date || undefined);
      if (date) {
        date.setHours(0, 0, 0, 0);

        onSelect({ start: date });
        setOpenDatePickerCalendar(false);
        hoveredDateRef.current = null;
      }
    } else {
      const [start, end] = dates as [Date | null, Date | null];

      if (start) start.setHours(0, 0, 0, 0);
      if (end) end.setHours(23, 59, 59, 999);

      setStartDate(start || undefined);
      setEndDate(end || undefined);

      // If a predefined range was selected previously, clear it
      if (start && !end) { setSelectedPredefinedRange(undefined); }

      if (start && end) {
        onSelect({ start, end });
        setOpenDatePickerCalendar(false);
        hoveredDateRef.current = null;
      }
    }
  };

  useOnClickOutside(ref, () => {
    if (startDate && !endDate) {
      hoveredDateRef.current = null;

      setEndDate(startDate);
      onSelect({ start: startDate, end: startDate });
    }
    setOpenDatePickerCalendar(false);
  });

  const isInSelectingRange = (date: Date) => {
    if (startDate && !endDate && hoveredDateRef.current) {
      return (
        (date > startDate && date <= hoveredDateRef.current!) ||
        (date < startDate && date >= hoveredDateRef.current!)
      );
    }
    return false;
  };

  const getDayClassName = (date: Date) => {
    // Border for currently hovered date
    if (isSameDay(date, hoveredDateRef.current!)) return 'border-2 border-brand-green';

    // If there's no endDate, highlight dates dynamically
    if (startDate && !endDate && startDate && !isSameDay(startDate, date)) {
      if (isInSelectingRange(date)) {
        return 'bg-brand-lime';
      }
    }

    return '';
  };

  // This useEffect is used to set the data-testid attribute on the input element of the DatePicker component
  useEffect(() => {
    const inputElement = document.querySelector('.react-datepicker__input-container input');
    if (inputElement) {
      inputElement.setAttribute('data-testid', 'date-picker-input');
    }
  }, []);

  return (
    <DatePicker
      selected={startDate}
      startDate={startDate}
      endDate={singleDate ? undefined : endDate}
      swapRange
      {...(singleDate ? { selectsStart: true } : { selectsRange: true })}
      minDate={minDate}
      maxDate={maxDate || undefined}
      readOnly={isDisabled}
      onChange={onChange}
      open={openDatePickerCalendar}
      inline={inline}
      placeholderText={t('inputs.DatePickerInput.chooseTimePeriod')}
      dateFormat="dd/MM/yyyy"
      popperPlacement="bottom-start"
      showDisabledMonthNavigation={true}
      monthsShown={1}
      calendarStartDay={1}
      formatWeekDay={day => dayLabels[day]}
      showIcon
      icon={<FontAwesomeIcon icon={faCalendar} onClick={() => { if (!isDisabled) setOpenDatePickerCalendar(true); }} />}
      className={classNames(className,
        'w-full h-full pl-8 pr-1 text-xs sm:text-sm rounded shadow-sm select-none caret-transparent',
        {
          'cursor-pointer text-brand-gray-light-1': !isDisabled,
          'cursor-not-allowed bg-brand-gray-light-4 text-brand-gray': isDisabled,
        }
      )}
      calendarIconClassName="h-4 w-4 text-brand-blue absolute top-1/2 left-2 transform -translate-y-1/2"
      calendarClassName="h-[300px] p-2 w-full"
      dayClassName={getDayClassName}
      onDayMouseEnter={date => {
        if (startDate && !endDate) {
          hoveredDateRef.current = date;
        }
      }}
      onInputClick={() => { if (!isDisabled) setOpenDatePickerCalendar(true); }}
      onKeyDown={e => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
          e.preventDefault();
        }
      }}
      renderCustomHeader={props => <CustomHeader {...props} minDate={minDate} maxDate={maxDate} />}
      calendarContainer={({ children }) =>
        showPredefinedRanges ? (
          <div className="flex w-full flex-col sm:flex-row h-80 sm:h-72" ref={ref}>
            <div className="w-auto">
              <PredefinedRanges
                selectedPredefinedRange={selectedPredefinedRange}
                setSelectedPredefinedRange={setSelectedPredefinedRange}
                daysUpToToday={daysUpToToday}
                setDaysUpToToday={setDaysUpToToday}
                onChange={onChange}
                {...props}
              />
            </div>
            <div className="w-full sm:w-80">{children}</div>
          </div>
        ) : (
          <div className="h-72 w-full" ref={ref}>
            {children}
          </div>
        )
      }
    />
  );
};
