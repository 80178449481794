type AirtableRecord = {
  id: string;
  created_time: Date;
  fields: any;
};

type ProductInventoryFields = {
  'Product Name': string;
  id: number;
  Cost: number;
  Manufacturer: string[];
  Type: 'PCBA' | 'Gateway' | 'Casing' | 'Washer' | 'Cable Assembly';
  'At Customer': number;
  'Internal Test': number;
  'External Test': number;
  'Units Arrived': number;
  'Units Out': number;
  'In stock': number;
  'Stock value': number;
  'Visible In Sales Order': boolean;
};

export type AirtableProductInventory = Omit<AirtableRecord, 'fields'> & {
  fields: ProductInventoryFields;
};

type PurchaseOrderFields = {
  'Units Arrived': number;
};

export type AirtablePurchaseOrder = Omit<AirtableRecord, 'fields'> & {
  fields: PurchaseOrderFields;
};

type SalesOrderFields = {
  'Customer Mail': string;
  'Customer Name': string;
  'Customer Org': string[];
  'Customer Phone': string;
  'Deal name': string;
  'Deal Owner': string;
  'Expected Duration': number;
  'Gateway amount': number;
  'Invoice Info': string;
  'Invoiced up front': number;
  'Sensor amount': number;
  // 'Sensor deadline': string;
  'Customer receive sensor by': string;
  // 'Shipping notes': string;
  // 'Shipping notes + extra info. Isolerede skruer? Speciel længde? Rondeller?': string;
  'Shipping address': string;
  'Order specifications': string;
  'Won Date': string;
  ID: number;
  LTV: number;
  // Status: 'Being prepared' | 'Created' | 'Prepare order' | 'Sensors shipped';
  'Shipping status':
    | 'Being prepared'
    | 'Created'
    | 'Prepare order'
    | 'Sensors shipped'
    | 'Cancelled';
  'Project name'?: string;
  'HubSpot deal ID'?: string;
  'HubSpot deal link'?: number;
};

export type AirtableSalesOrder = Omit<AirtableRecord, 'fields'> & {
  fields: SalesOrderFields;
};

type ProjectFields = {
  Name: string;
  'Sales Orders'?: string;
  ID: number;
  'Created date': string;
  // 'Sales Orders 2': string[];
};

export type AirtableProject = Omit<AirtableRecord, 'fields'> & {
  fields: ProjectFields;
};

export const parseAirtableRecord = (record: any): AirtableRecord => {
  record.createdTime = new Date(record.createdTime);
  return record as AirtableRecord;
};

export const parseAirtableProductInventory = (record: any) =>
  parseAirtableRecord(record) as AirtableProductInventory;
export const parseAirtablePurchaseOrder = (record: any) =>
  parseAirtableRecord(record) as AirtablePurchaseOrder;
export const parseAirtableSalesOrder = (record: any) =>
  parseAirtableRecord(record) as AirtableSalesOrder;
export const parseAirtableProject = (record: any) => parseAirtableRecord(record) as AirtableProject;

export const ProductIDRoundel = 'recUIuuueHwIuUiem';
