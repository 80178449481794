import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import { useSelectedCustomer } from 'utils/hooks/useSelectedCustomer';
import AlarmRule, {
  parseAlarmRule,
  getQueryKeyAlarmRule,
  getQueryKeyAdminAlarmRules,
} from 'utils/types/AlarmRule';
import AlarmRuleCriterion from 'utils/types/AlarmRuleCriterion';
import AlarmRuleLocaleDetail from 'utils/types/AlarmRuleLocaleDetail';
import { getQueryKeyCustomerAlarmRules } from 'utils/types/Customer';
import HookOptions from 'utils/types/HookOptions';

export const useAlertRule = (
  alertRuleId?: string,
  { enableGet = true, disableErrorNotifications = false }: HookOptions = {},
) => {
  const queryKey = getQueryKeyAlarmRule(alertRuleId!);

  const queryClient = useQueryClient();
  const { customerId } = useSelectedCustomer();

  // Get
  const {
    data: alertRule,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/alarm_rules/${alertRuleId}`, {
        _disableErrorNotifications: disableErrorNotifications,
      }),
    select: (alarm_rule: any) => parseAlarmRule(alarm_rule),
    enabled: !!alertRuleId && enableGet,
  });

  // Update
  const { mutateAsync: updateAlertRule } = useMutation({
    mutationKey: queryKey,
    mutationFn: async (
      values: Pick<
        AlarmRule,
        'name' | 'type' | 'active_period_from' | 'active_period_to' | 'repeat_yearly'
      >,
    ): Promise<AlarmRule> => {
      const alertRule = await api.patch(`/alarm_rules/${alertRuleId}`, values);
      return parseAlarmRule(alertRule);
    },
    onSuccess: async alertRule => {
      queryClient.setQueryData(queryKey, alertRule);
      queryClient.invalidateQueries({ queryKey: getQueryKeyCustomerAlarmRules(customerId!) });
      queryClient.invalidateQueries({ queryKey: getQueryKeyAdminAlarmRules() });
    },
  });

  // Delete
  const { mutateAsync: deleteAlertRule } = useMutation({
    mutationKey: queryKey,
    mutationFn: () => api.delete(`/alarm_rules/${alertRuleId}`),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: getQueryKeyCustomerAlarmRules(customerId!) });
      queryClient.invalidateQueries({ queryKey: getQueryKeyAdminAlarmRules() });
    },
  });

  // Create alarm rule criterion
  const { mutateAsync: createAlertRuleCriterion } = useMutation({
    mutationFn: async (
      values: Pick<
        AlarmRuleCriterion,
        'parameter' | 'comparison_operator' | 'value' | 'type' | 'delayed_days'
      >,
    ): Promise<AlarmRule> => {
      const alertRule = await api.post(`/alarm_rules/${alertRuleId}/criteria`, values);
      return parseAlarmRule(alertRule);
    },
    onSuccess: (alarmRule: AlarmRule): void => {
      // Save new alarm rule to cache
      queryClient.setQueryData(queryKey, alarmRule);
    },
  });

  // Delete alarm rule criterion
  const { mutateAsync: deleteAlertRuleCriterion } = useMutation({
    mutationFn: async (alarmRuleCriterionId: string): Promise<AlarmRule> => {
      const alarmRule = await api.delete(
        `/alarm_rules/${alertRuleId}/criteria/${alarmRuleCriterionId}`,
      );
      return parseAlarmRule(alarmRule);
    },
    onSuccess: (alarmRule: AlarmRule): void => {
      // Save new alarm rule to cache
      queryClient.setQueryData(queryKey, alarmRule);
    },
  });

  // Create alarm rule locale detail
  const { mutateAsync: createAlertRuleLocaleDetail } = useMutation({
    mutationFn: async (
      values: Pick<AlarmRuleLocaleDetail, 'locale' | 'name'>,
    ): Promise<AlarmRule> => {
      const alarmRule = await api.post(`/alarm_rules/${alertRuleId}/locale_details`, values);
      return parseAlarmRule(alarmRule);
    },
    onSuccess: (alarmRule: AlarmRule): void => {
      // Save new alarm rule to cache
      queryClient.setQueryData(queryKey, alarmRule);
    },
  });

  // Update alarm rule locale detail
  const { mutateAsync: updateAlertRuleLocaleDetailById } = useMutation({
    mutationFn: async ({
      id,
      ...values
    }: Pick<AlarmRuleLocaleDetail, 'id' | 'locale' | 'name'>): Promise<AlarmRule> => {
      const alarmRule = await api.patch(`/alarm_rules/${alertRuleId}/locale_details/${id}`, values);
      return parseAlarmRule(alarmRule);
    },
    onSuccess: (alarmRule: AlarmRule): void => {
      // Save new alarm rule to cache
      queryClient.setQueryData(queryKey, alarmRule);
    },
  });

  // Delete alarm rule locale detail
  const { mutateAsync: deleteAlertRuleLocaleDetailById } = useMutation({
    mutationFn: async (id: string): Promise<AlarmRule> => {
      const alarmRule = await api.delete(`/alarm_rules/${alertRuleId}/locale_details/${id}`);
      return parseAlarmRule(alarmRule);
    },
    onSuccess: (alarmRule: AlarmRule): void => {
      // Save new alarm rule to cache
      queryClient.setQueryData(queryKey, alarmRule);
    },
  });

  return {
    alertRule,
    deleteAlertRule,
    updateAlertRule,
    createAlertRuleCriterion,
    deleteAlertRuleCriterion,
    createAlertRuleLocaleDetail,
    updateAlertRuleLocaleDetailById,
    deleteAlertRuleLocaleDetailById,
    isPending: isPending || isLoading || isFetching,
  };
};
