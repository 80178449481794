import { Dispatch, SetStateAction, useState } from 'react';

import { DatePickerInput } from 'components';
import {
  MeasureTypeDropdown,
  TagTypeDropdown,
} from 'components/plots/insights_plots/sensors/components/';

export const SensorDataOptions: React.FC<{
  startDate: Date;
  endDate: Date;
  setStartDate: Dispatch<SetStateAction<Date>>;
  setEndDate: Dispatch<SetStateAction<Date>>;
  setMeasureType: Dispatch<SetStateAction<string>>;
  setTagType: Dispatch<SetStateAction<string>>;
}> = ({ startDate, endDate, setStartDate, setEndDate, setMeasureType, setTagType }) => {
  const [openDatePickerCalendar, setOpenDatePickerCalendar] = useState(false);

  return (
    <div className="grid grid-cols-4 auto-cols-min my-4 w-2/4 gap-y-1">
      <div className="flex flex-row-reverse items-center pr-4">Date Range</div>
      <div className="col-span-3">
        <DatePickerInput
          key={`${startDate}-${endDate}`}
          onSelect={({ start, end }) => {
            if (start && end) {
              setStartDate(start);
              setEndDate(end);
            }
          }}
          initialStartDate={startDate}
          initialEndDate={endDate}
          showPredefinedRanges={true}
          openDatePickerCalendar={openDatePickerCalendar}
          setOpenDatePickerCalendar={setOpenDatePickerCalendar}
          maxDate={new Date()}
        />
      </div>
      <div className="flex flex-row-reverse items-center pr-4">Measure Type</div>
      <div className="col-span-3">
        <MeasureTypeDropdown setMeasureType={setMeasureType} />
      </div>
      <div className="flex flex-row-reverse items-center pr-4">Tag Type</div>
      <div className="col-span-3">
        <TagTypeDropdown setTagType={setTagType} />
      </div>
    </div>
  );
};
