import { format } from 'date-fns';

import { LineMarkerData } from 'components/plots/helpers/types';
import { brandBlueLight1, brandGrayLight3, brandLimeTint2, brandOrangeLight1 } from 'utils/colors';
import { Event } from 'utils/types';

const lineMarkerColorMap = new Map<string, string>([
  ['alert', brandOrangeLight1],
  ['alert_toggle', brandGrayLight3],
  ['key_event', brandBlueLight1],
  ['note', brandLimeTint2],
  ['added_to_blueprint', brandBlueLight1],
  ['undefined', brandGrayLight3],
]);

export const eventsToLineMarkerData = (events: Event[], maxYValue: number): LineMarkerData[][] =>
  events.map(event => [
    {
      name: `[EVENT_START] ${event.name}`,
      text: event.body_text,
      label: { show: false },
      type: 'event',
      xAxis: `${format(event.timestamp, 'yyyy-MM-dd')}T${format(event.timestamp, 'HH:mm')}`,
      yAxis: 0,
      lineStyle: {
        color: lineMarkerColorMap.get(event.type) || lineMarkerColorMap.get('undefined'),
      },
    },
    {
      name: `[EVENT_END] ${event.name}`,
      text: event.body_text,
      label: { show: false },
      type: 'event',
      xAxis: `${format(event.timestamp, 'yyyy-MM-dd')}T${format(event.timestamp, 'HH:mm')}`,
      yAxis: maxYValue,
    },
  ]);
