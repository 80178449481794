import { closestTo } from 'date-fns/closestTo';
import { differenceInHours } from 'date-fns/differenceInHours';
import { isBefore } from 'date-fns/isBefore';
import { subHours } from 'date-fns/subHours';

import SensorType from 'utils/enums/SensorType';
import { BlueprintViewStateType } from 'utils/types';
import Sensor from 'utils/types/Sensor';
import Transmission from 'utils/types/Transmission';
import TransmissionAnomaly from 'utils/types/TransmissionAnomaly';

const parseTransmissionsMixed = ({
  transmissions,
  sensors,
  anomalies,
}: {
  transmissions?: Transmission[];
  sensors?: Sensor[];
  anomalies?: TransmissionAnomaly[];
}) => {
  const hardwareId2sensorType: { [key: string]: SensorType } = {};
  const hardwareId2transmissions: { [key: string]: Transmission[] } = {};
  sensors?.forEach(sensor => {
    const hardwareId = sensor.hardware_id || 'undefined';
    hardwareId2sensorType[hardwareId] = sensor.type;
    hardwareId2transmissions[hardwareId] = [];
  });

  // Use corrected moisture values based on wood type
  transmissions?.forEach(transmission => {
    // Make sure missing sensors are handled
    if (!hardwareId2transmissions[transmission.hardware_id]) {
      hardwareId2transmissions[transmission.hardware_id] = [];
    }

    hardwareId2transmissions[transmission.hardware_id].push(transmission);
  });

  const parsedTransmissions = Object.keys(hardwareId2transmissions)
    .map(hardwareId => chainInvalidTransmissions(hardwareId2transmissions[hardwareId]))
    .flat();

  if (anomalies) {
    const transmissionId2anomaly: { [key: string]: TransmissionAnomaly } = anomalies?.reduce(
      (o: any, anomaly: TransmissionAnomaly) => ({
        ...o,
        [anomaly.transmission_id]: anomaly,
      }),
      {},
    );

    // Save any transmission anomaly on the transmission object
    parsedTransmissions.forEach((transmission: Transmission) => {
      transmission.anomaly = transmissionId2anomaly[transmission.id];
    });
  }

  return parsedTransmissions;
};

const chainInvalidTransmissions = (transmissions: Transmission[]) => {
  if (transmissions.length === 0) return [];

  // Chain moisture values
  // For any given invalid transmission use the previous value
  for (let i = 0; i < transmissions.length ; i++) {
    const transmission = transmissions[i];

    if (transmission.isInvalid) {
      if (transmission.ohms === 999_999){
        // Resistance too high to measure
        transmission.moisture = 2;  
      } else if (transmission.ohms === 999_997 || transmission.ohms === 0.001) {
        // Resistance too low to measure
        transmission.moisture = 100; 
      } else if (i > 0){
        // Something else went wrong, use the previous value if available
        const previousTransmission = transmissions[i - 1];
        transmission.moisture = previousTransmission.moisture;
      } else {
        // Something else went wrong, no previous value to fallback to
        transmission.moisture = 50; 
      }
    }
  }

  return transmissions;
};

export const getSensorTransmission = (
  timeTo: Date,
  max: number,
  hours: number,
  sensorId2Transmission: { [key: string]: Transmission[] | undefined } | undefined,
  sensorId: string,
  blueprintViewState: BlueprintViewStateType,
) => {
  const date = subHours(timeTo, max - hours);
  const transmissionsDates = !!sensorId2Transmission
    ? (sensorId2Transmission[sensorId] || [])
        .map(transmission => transmission.timestamp)
        .filter(transmissionDate => isBefore(transmissionDate, date))
    : [];

  const closestTimestamp = closestTo(date, transmissionsDates);

  // If we looking at risk score we need to allow looking much further back in time
  const maxDelay = blueprintViewState === BlueprintViewStateType.RiskScore ? 720 : 24;

  if (closestTimestamp && differenceInHours(timeTo, closestTimestamp!) - max + hours > maxDelay) {
    // If the closest transmission is more than 48 hours away, consider this as no transmission available
    return {} as Transmission;
  }

  const transmission = sensorId2Transmission
    ? sensorId2Transmission[sensorId]?.find(
        transmission => transmission.timestamp.getTime() === closestTimestamp?.getTime(),
      ) || ({} as Transmission)
    : ({} as Transmission);

  return transmission;
};

export { chainInvalidTransmissions, parseTransmissionsMixed };
