import React from 'react';
import { Link } from 'react-router-dom';

import { BlueprintItemCard } from 'components';
import { Blueprint } from 'utils/types';

export const BlueprintItemWithSettingsCard: React.FC<{
  blueprintId?: string;
  onSelect?: (blueprint: Blueprint) => Promise<void>;
}> = ({ blueprintId, onSelect }) => (
  <div className="relative">
    <Link to={`/user/blueprints/${blueprintId}`}>
      <BlueprintItemCard blueprintId={blueprintId} onSelect={onSelect} />
    </Link>
  </div>
);
