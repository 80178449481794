import { t } from 'translations';
import { RiskScoreState } from 'utils/enums';
import MoistureState from 'utils/enums/MoistureState';
import SignalStrengthState from 'utils/enums/SignalStrengthState';
import { getSignalStrengthStateText } from 'utils/sensor/texts';
import { BlueprintViewStateType } from 'utils/types';

export const getScaleStateBlueprint = (blueprintViewState: BlueprintViewStateType) => {
  if (blueprintViewState === BlueprintViewStateType.Moisture)
    return [
      {
        value: MoistureState.INACTIVE,
        label: t('utils:sensor.texts.MoistureState.InActive'),
        getTooltip: () => t('utils:blueprint.scale.gradientDataInactive'),
      },
      {
        value: MoistureState.INVALID,
        label: t('utils:sensor.texts.MoistureState.Invalid'),
        getTooltip: () => t('utils:blueprint.scale.gradientDataInvalid'),
      },
      {
        value: MoistureState.MOISTURE_0_10,
        label: '0',
        getTooltip: () =>
          t('utils:blueprint.scale.Moisture.gradientDataBetween', {
            value1: '0',
            value2: '10',
          }),
      },
      {
        value: MoistureState.MOISTURE_10_15,
        label: '10',
        getTooltip: () =>
          t('utils:blueprint.scale.Moisture.gradientDataBetween', {
            value1: '10',
            value2: '15',
          }),
      },
      {
        value: MoistureState.MOISTURE_15_20,
        label: '15',
        getTooltip: () =>
          t('utils:blueprint.scale.Moisture.gradientDataBetween', {
            value1: '15',
            value2: '20',
          }),
      },
      {
        value: MoistureState.MOISTURE_20_25,
        label: '20',
        getTooltip: () =>
          t('utils:blueprint.scale.Moisture.gradientDataBetween', {
            value1: '20',
            value2: '25',
          }),
      },
      {
        value: MoistureState.MOISTURE_25_30,
        label: '25',
        getTooltip: () =>
          t('utils:blueprint.scale.Moisture.gradientDataBetween', {
            value1: '25',
            value2: '30',
          }),
      },
      {
        value: MoistureState.MOISTURE_30_35,
        label: '30',
        getTooltip: () =>
          t('utils:blueprint.scale.Moisture.gradientDataBetween', {
            value1: '30',
            value2: '35',
          }),
      },
      {
        value: MoistureState.MOISTURE_35_40,
        label: '35',
        getTooltip: () =>
          t('utils:blueprint.scale.Moisture.gradientDataBetween', {
            value1: '35',
            value2: '40',
          }),
      },
      {
        value: MoistureState.MOISTURE_40_100,
        label: '40',
        getTooltip: () =>
          t('utils:blueprint.scale.Moisture.gradientDataAbove', {
            value: '40',
          }),
      },
    ];
  else if (blueprintViewState === BlueprintViewStateType.SignalStrength)
    return [
      {
        value: SignalStrengthState.INACTIVE,
        label: getSignalStrengthStateText(SignalStrengthState.INACTIVE),
        getTooltip: () => t('utils:blueprint.scale.gradientDataInactive'),
      },
      {
        value: SignalStrengthState.VERY_GOOD,
        label: getSignalStrengthStateText(SignalStrengthState.VERY_GOOD),
        getTooltip: () => t('utils:blueprint.scale.SignalStrength.VeryGood'),
      },
      {
        value: SignalStrengthState.GOOD,
        label: getSignalStrengthStateText(SignalStrengthState.GOOD),
        getTooltip: () => t('utils:blueprint.scale.SignalStrength.Good'),
      },
      {
        value: SignalStrengthState.FAIR,
        label: getSignalStrengthStateText(SignalStrengthState.FAIR),
        getTooltip: () => t('utils:blueprint.scale.SignalStrength.Fair'),
      },
      {
        value: SignalStrengthState.MODERATE,
        label: getSignalStrengthStateText(SignalStrengthState.MODERATE),
        getTooltip: () => t('utils:blueprint.scale.SignalStrength.Moderate'),
      },
      {
        value: SignalStrengthState.POOR,
        label: getSignalStrengthStateText(SignalStrengthState.POOR),
        getTooltip: () => t('utils:blueprint.scale.SignalStrength.Poor'),
      },
      {
        value: SignalStrengthState.VERY_POOR,
        label: getSignalStrengthStateText(SignalStrengthState.VERY_POOR),
        getTooltip: () => t('utils:blueprint.scale.SignalStrength.VeryPoor'),
      },
    ];
  else if (blueprintViewState === BlueprintViewStateType.RiskScore)
    return [
      {
        value: RiskScoreState.UNKNOWN,
        label: 'n/a',
        getTooltip: () => t('utils:blueprint.scale.RiskScore.Unknown'),
      },
      {
        value: RiskScoreState.NO_RISK,
        label: '0',
        getTooltip: () => t('utils:blueprint.scale.RiskScore.NoRisk'),
      },
      {
        value: RiskScoreState.RISK_SCORE_VERY_LOW,
        label: '1',
        getTooltip: () => t('utils:blueprint.scale.RiskScore.VeryLow'),
      },
      {
        value: RiskScoreState.RISK_SCORE_LOW,
        label: '2',
        getTooltip: () => t('utils:blueprint.scale.RiskScore.Low'),
      },
      {
        value: RiskScoreState.RISK_SCORE_MODERATE,
        label: '3',
        getTooltip: () => t('utils:blueprint.scale.RiskScore.Moderate'),
      },
      {
        value: RiskScoreState.RISK_SCORE_HIGH,
        label: '4',
        getTooltip: () => t('utils:blueprint.scale.RiskScore.High'),
      },
      {
        value: RiskScoreState.RISK_SCORE_VERY_HIGH,
        label: '5',
        getTooltip: () => t('utils:blueprint.scale.RiskScore.VeryHigh'),
      },
    ];
  else return [];
};
