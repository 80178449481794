import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import Permission from 'utils/enums/Permission';
import SensorGroup from 'utils/types/SensorGroup';

export const useUtilsPermissionsGroups = (
  groups?: SensorGroup[],
  permission: Permission = Permission.View,
) => {
  const groupIds = (groups || []).map(group => group.id);

  const queryKey = ['useUtilsPermissionsGroups', groupIds];

  const { data: groupPermissions, isPending } = useQuery({
    queryKey,
    queryFn: async () =>
      api.post(`/utils/permissions/groups_bulk?permission=${permission}`, groupIds),
    select: (groupPermissions: any) => groupPermissions as { [key: string]: boolean },
  });

  return {
    groupPermissions,
    isPending,
  };
};
