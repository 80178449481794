import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HTMLAttributes } from 'react';

import ExternalLink from 'components/ExternalLink';
import { faHubSpotLogo } from 'components/icons';

export const HubSpotDealLink: React.FC<
  {
    hubSpotDealId?: string;
    className?: string;
    text?: string;
  } & Omit<HTMLAttributes<HTMLAnchorElement>, 'title'>
> = ({ hubSpotDealId, text, ...props }) =>
  hubSpotDealId ? (
    <ExternalLink
      url={`https://app-eu1.hubspot.com/contacts/144490397/record/0-3/${hubSpotDealId}/`}
      text={
        <>
          <FontAwesomeIcon className="mr-1 text-brand-orange" icon={faHubSpotLogo} />
          HubSpot Deal
        </>
      }
      title={hubSpotDealId}
      {...props}
    />
  ) : (
    'n/a'
  );
