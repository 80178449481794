import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueprintCanvas } from 'components/BlueprintCanvas';
import { BlueprintAttachedSensorsField } from 'components/BlueprintSensorPositioning/components';
import Sensor from 'utils/types/Sensor';

export const BlueprintSensorPositioning: React.FC<{
  blueprintId: string;
  availableSensors?: Sensor[];
  onlyEnableSensorIds?: string[];
}> = ({ blueprintId, onlyEnableSensorIds, availableSensors }) => {
  // We need to define this here until we bring the sensor carousel behaviour to the canvas sidebar,
  // see https://linear.app/woodsense/issue/SW-732/sidebar-edit-mode
  const [editModeEnabled, setEditMode] = useState(false);

  const { t } = useTranslation('components');

  return (
    <div className="mb-3">
      <BlueprintCanvas
        blueprintId={blueprintId}
        onlyEnableSensorIds={onlyEnableSensorIds}
        showHeader
        showSensors
        showPlayableTimeline
        showToolbox
        enableWheelScrolling
        enableDragging
        editModeEnabled={editModeEnabled}
        setEditMode={setEditMode}
      />
      <div className="px-6 lg:px-7">
        <div className="mt-3 mb-1 flex justify-between items-center">
          <p className="mt-3 mb-1">
            {t('blueprints.blueprintSensorPositioning.BlueprintAttachedSensorsField.text.1')}
          </p>
        </div>

        <BlueprintAttachedSensorsField
          blueprintId={blueprintId}
          availableSensors={availableSensors}
          disabled={!editModeEnabled}
        />
      </div>
    </div>
  );
};
