import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import { parseSensorGroup } from 'utils/types/SensorGroup';

export const useAlertRuleGroups = (alertRuleId?: string) => {
  const queryKey = ['useAlertRuleGroups', alertRuleId];

  const queryClient = useQueryClient();

  // Get
  const {
    data: groups,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(`/alarm_rules/${alertRuleId}/groups`),
    select: (groups: any[]) => groups.map(parseSensorGroup),
    enabled: !!alertRuleId,
  });

  // Enable alert rule for group
  const { mutateAsync: enableAlertRuleForGroupById, isPending: isPendingEnabling } = useMutation({
    mutationFn: (groupId: string) =>
      api.post(`/sensor_groups/${groupId}/alert_rules/${alertRuleId}`),
    onSuccess: async (data, alarmRuleId) => {
      queryClient.invalidateQueries({
        queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ['useGroupAlertRules'],
      });
    },
  });

  // Disable alert rule for group
  const { mutateAsync: disableAlertRuleForGroupById, isPending: isPendingDisabling } = useMutation({
    mutationFn: (groupId: string) =>
      api.delete(`/sensor_groups/${groupId}/alert_rules/${alertRuleId}`),
    onSuccess: async (data, alarmRuleId) => {
      queryClient.invalidateQueries({
        queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ['useGroupAlertRules'],
      });
    },
  });

  return {
    groups,
    enableAlertRuleForGroupById,
    disableAlertRuleForGroupById,
    isPending: isPending || isLoading || isFetching || isPendingEnabling || isPendingDisabling,
  };
};
