import { format } from 'date-fns/format';
import { formatRelative } from 'date-fns/formatRelative';
import { da } from 'date-fns/locale/da';
import { enGB } from 'date-fns/locale/en-GB';

import { i18 } from 'translations';

export const getLocale = () => {
  switch (i18.language) {
    case 'da':
      return da;
    case 'en':
      return enGB;
    default:
      return da;
  }
};

export const formatDate = (date: Date | null) => {
  if (!date) return '';
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const isoToPrettyDate = (isoString: string) => {
  const date = new Date(isoString);
  return dateToPrettyDate(date);
};

export const roundToNearestHour = (date: Date) => {
  const dateRounded = new Date(date);
  dateRounded.setMinutes(dateRounded.getMinutes() + 30);
  dateRounded.setMinutes(0, 0, 0);
  return dateRounded;
};

export const dateToPrettyDate = (date?: Date) => {
  if (!date) return 'n/a';
  if (!dateIsValid(date)) return 'n/a';
  if (new Date() < date) return date.toDateString();
  return formatRelative(date, new Date(), { locale: getLocale() });
};

export const dateToLocaleString = (
  date?: Date,
  { dateOnly }: { dateOnly?: boolean } = {},
): string => {
  if (!date) return '';
  if (!dateIsValid(date)) return 'n/a';
  return date.toLocaleString(
    getLocale() === da ? 'da-DK' : 'en-GB',
    dateOnly
      ? {
          month: '2-digit',
          year: 'numeric',
          day: '2-digit',
        }
      : {
          month: '2-digit',
          year: 'numeric',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        },
  );
};

export const dateToFormattedString = (date?: Date) => {
  if (!date) return 'n/a';
  if (!dateIsValid(date)) return 'n/a';
  return format(date, 'yyyy-MM-dd HH:mm:ss');
};

export const dateToFormattedDateString = (date?: Date) => {
  if (!date) return 'n/a';
  if (!dateIsValid(date)) return 'n/a';
  return format(date, 'yyyy-MM-dd');
};

export const dateIsValid = (date: Date) => {
  if (!date) return false;
  if (typeof date === 'string') return false;
  return !isNaN(date.getTime());
};

export const isDateObject = (x: any) =>
  x && Object.prototype.toString.call(x) === '[object Date]' && !isNaN(x);

export const getDefaultDateRange = () => {
  // Get time of coming midnight
  // E.g. 14:28:03 -> 23:59:59
  const now = ceilToEndOfDay(new Date());

  const oneWeekBack = new Date(now.getTime());
  oneWeekBack.setDate(oneWeekBack.getDate() - 7);

  return {
    start: oneWeekBack,
    end: now,
  };
};

export const getDatesBetweenDates = (startDate: Date, endDate: Date, includeEnd = true) => {
  const dates = [];
  //to avoid modifying the original date
  const theDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  while (theDate < endDate) {
    dates.push(new Date(theDate));
    theDate.setDate(theDate.getDate() + 1);
  }
  if (includeEnd) dates.push(endDate);
  return dates;
};

export const floorToLatest5Minutes = (date: Date) => {
  const newDate = new Date(date.getTime());
  newDate.setMinutes(Math.floor(newDate.getMinutes() / 5) * 5, 0, 0);
  return newDate;
};

export const floorToMidnight = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

export const ceilToEndOfDay = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);

export const getHeartbeatDefaultDateRange = () => {
  // End of month and start of month 5 months back
  const now = new Date();
  const start = new Date(now.getFullYear(), now.getMonth() - 5, 1);
  const end = new Date(now.getFullYear(), now.getMonth() + 1, 1);

  return {
    start,
    end,
  };
};
