import classNames from 'classnames';
import { useContext } from 'react';

import { SensorAlertRuleColumn } from 'components/SensorsAlertRulesMatrix/components/SensorAlertRuleColumn';
import { SensorsAlertRulesMatrixContext } from 'components/SensorsAlertRulesMatrix/components/SensorsAlertRulesMatrixContext';
import AlarmRule from 'utils/types/AlarmRule';
import Sensor from 'utils/types/Sensor';

export const SensorAlertRulesRow: React.FC<{
  rowIndex: number;
  sensor: Sensor;
  alertRules?: AlarmRule[];
}> = ({ rowIndex, sensor, alertRules }) => {
  const { hoveringRowIndex, setHoveringRowIndex, setHoveringColumnIndex } = useContext(
    SensorsAlertRulesMatrixContext,
  );

  return (
    <div
      className={classNames('flex justify-normal', {
        'bg-brand-gray-light-4': rowIndex % 2 === 0 && hoveringRowIndex !== rowIndex,
        'bg-brand-lime': hoveringRowIndex === rowIndex,
      })}
      onMouseEnter={() => setHoveringRowIndex(rowIndex)}
    >
      <div
        className="w-32"
        onMouseEnter={() => {
          setHoveringColumnIndex(-1);
          setHoveringRowIndex(rowIndex);
        }}
      >
        <p
          className="py-1 text-sm hover:underline hover:cursor-pointer"
          data-tooltip-id="route-tooltip"
        >
          {sensor.name}
        </p>
      </div>
      {alertRules?.map((alertRule, index) => (
        <SensorAlertRuleColumn
          key={index}
          rowIndex={rowIndex}
          columnIndex={index}
          sensor={sensor}
          alertRule={alertRule}
        />
      ))}
    </div>
  );
};
