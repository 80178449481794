import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ResolveAlertModal } from 'components';
import { useAlarm, useAlertReadByUsers } from 'utils/hooks/data';

export const ResolveButton = ({ alertId }: { alertId: string }) => {
  const { t } = useTranslation('components');

  const [isResolveModalOpen, setIsResolveModalOpen] = useState(false);

  const { alarm, resolveAlert } = useAlarm(alertId);

  const { markAlertReadByCurrentUser } = useAlertReadByUsers(alertId);

  const isResolved = alarm?.resolved_by !== null;

  return (
    <>
      <button
        className={classNames('border rounded-lg p-2 bg-brand-blue text-white', {
          'bg-brand-blue-light-1 opacity-80': isResolved,
        })}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();

          setIsResolveModalOpen(true);
        }}
        disabled={isResolved}
      >
        {isResolved ? (
          <div className="flex items-center gap-1">
            <span>{t('buttons.ResolveButton.resolved')}</span>
            <FontAwesomeIcon icon={faCheck} />
          </div>
        ) : (
          <>{t('buttons.ResolveButton.resolve')}</>
        )}
      </button>
      <ResolveAlertModal
        show={isResolveModalOpen}
        setShow={setIsResolveModalOpen}
        onSubmit={async ({ resolveReason }, { resetForm }) => {
          await resolveAlert(resolveReason);
          await markAlertReadByCurrentUser();

          resetForm();
          setIsResolveModalOpen(false);
        }}
      />
    </>
  );
};
