type EventType =
  | 'alert'
  | 'alert_toggle'
  | 'key_event'
  | 'note'
  | 'added_to_blueprint'
  | 'undefined';

export type Event = {
  name: string;
  body_text: string;
  timestamp: Date;
  type: EventType;
};

export const parseEvent = (event: any): Event => {
  event.timestamp = new Date(event.timestamp);
  return event as Event;
};
