import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { ActionBarHeader } from 'components/BlueprintCanvas/components/ActionBar/ActionBarHeader';
import { BlueprintSettingsDropdown } from 'components/BlueprintCanvas/components/BlueprintSettingsDropdown';
import { MeasurementTypeSelector } from 'components/BlueprintCanvas/components/MeasurementTypeSelect';
import { Button } from 'components/buttons';
import { faCircleQuestionLine } from 'components/icons';

export const ActionBar: React.FC<{
  blueprintId: string;
  blueprintTitle: string;
  blueprintGroup: string;
  blueprintGroupId: string;
  blueprintTags: string;
  editModeEnabled: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  blueprintId,
  blueprintTitle,
  blueprintGroup,
  blueprintTags,
  blueprintGroupId,
  editModeEnabled,
  setEditMode,
}) => {
  const { t } = useTranslation('components');
  const location = useLocation();

  return (
    <div className="flex items-start py-3 gap-2 px-4 w-full h-fit bg-brand-green text-brand-beige rounded-t-lg border-b-2 border-brand-green-light-2 ">
      <div className="w-1/3">
        <ActionBarHeader
          blueprintTitle={blueprintTitle}
          blueprintTags={blueprintTags}
          blueprintGroupId={blueprintGroupId}
          blueprintGroup={blueprintGroup}
        />
      </div>
      <div className="flex items-center justify-center w-1/3">
        <MeasurementTypeSelector id={blueprintId} />
      </div>
      <div className="flex self-center relative flex-row-reverse items-center h-full w-1/3 gap-2">
        <div className="flex flex-row-reverse gap-2">
          <BlueprintSettingsDropdown
            blueprintId={blueprintId}
            onDeleteRedirectPath={'user/blueprints'}
          />
          <Link
            className="bg-transparent w-7 h-7 hover:text-brand-beige-light-2"
            to={t('links.BlueprintGuideLink.url')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className="w-full h-full " icon={faCircleQuestionLine} />
          </Link>
        </div>
        {!location.pathname.includes('sensors') && (
          <Button className="flex items-center justify-center" onClick={() => setEditMode(val => !val)}>
            <FontAwesomeIcon className="mr-2" icon={editModeEnabled ? faUnlock : faLock} />
            <div className="text-xs xl:text-sm text-nowrap">{editModeEnabled
              ? t('blueprints.ActionBar.confirmChanges')
              : t('blueprints.ActionBar.editBlueprint')}</div>
          </Button>
        )}
        {editModeEnabled && (
          <div className="hidden xl:block text-base text-brand-beige ">{t('blueprints.ActionBar.editMode')}</div>
        )}
      </div>
    </div>
  );
};

export default ActionBar;
