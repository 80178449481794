import i18n, { t } from 'translations';
import AlarmType from 'utils/enums/AlarmType';
import { parseI18LanguageToLocale } from 'utils/enums/Locale';
import AlarmRule from 'utils/types/AlarmRule';

export const getAlertName = (alarmType: AlarmType, alertRule: AlarmRule | undefined) => {
  const currentLocale = parseI18LanguageToLocale(i18n?.language ?? 'da');

  switch (alarmType) {
    case AlarmType.CustomAlarmRule:
      const fallbackName = alertRule?.name ?? t('utils:texts.AlertName.CustomAlert');
      return alertRule?.alarm_rule_locale_details
        ? (alertRule?.alarm_rule_locale_details?.find(
            localeDetail => localeDetail.locale === currentLocale,
          )?.name ?? fallbackName)
        : fallbackName;
    case AlarmType.MissingValues48Hours:
    case AlarmType.MissingValues1Week:
    case AlarmType.MissingValues4Weeks:
      return t('utils:texts.AlertName.InactiveSensorAlert');
    case AlarmType.GatewayMissingHeartbeats24Hours:
    case AlarmType.GatewayMissingHeartbeats1Week:
    case AlarmType.GatewayMissingHeartbeats4Weeks:
      return t('utils:texts.AlertName.InactiveGatewayAlert');
    case AlarmType.SensativeFloodAlarm:
      return t('utils:texts.AlertName.FloodAlert');
    case AlarmType.ReferenceValues:
      return t('utils:texts.AlertName.ReferenceValuesAlert');
    case AlarmType.InternalNewDevice:
      return t('utils:texts.AlertName.NewDeviceAlert');
    case AlarmType.InternalMoistureIncreased:
      return t('utils:.texts.AlertName.MoistureIncreasedAlert');
    default:
      return t('utils:.texts.AlertName.UnknownAlert');
  }
};
