import isEqual from 'lodash/isEqual';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';

import { MultiSelect } from 'components/inputs/components';
import { OptionType } from 'components/inputs/components/MultiSelect/types';
import { GroupsSelectInputProps } from 'components/inputs/GroupsSelectInput/types';
import { sortedArrayByStringProperty } from 'utils/arrays';

const GroupsSelectInputInner = (
  {
    disabled,
    initialSelectedGroupIds,
    disabledGroupIds,
    groupsToSelect,
    label,
    groupIdToTooltip,
    showDescriptions,
    ...props
  }: GroupsSelectInputProps,
  ref: React.Ref<any>,
) => {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<OptionType>>();

  const { t } = useTranslation('components');

  // Define options array
  const options: {
    label: string;
    options: OptionType[];
  }[] = useMemo(
    () => [
      {
        label: t('inputs.GroupsSelectInput.groupsYouHaveAccessTo'),
        options: sortedArrayByStringProperty(
          (groupsToSelect ?? []).map(group => ({
            value: group.id,
            label: group.name,
            description: showDescriptions ? group.description : undefined,
            isDisabled: disabledGroupIds?.includes(group.id),
            tooltip: groupIdToTooltip?.[group?.id],
          })),
          'label',
        ),
      },
    ],
    [groupsToSelect, showDescriptions, disabledGroupIds, groupIdToTooltip, t],
  );

  const optionsCount = options.map(option => option.options.length).reduce((a, b) => a + b, 0);

  useEffect(() => {
    if (initialSelectedGroupIds) {
      const optionsFlat = options.map(option => option.options).flat();
      const optionsSelectedFlat = optionsFlat.filter(option =>
        initialSelectedGroupIds.includes(option.value),
      );

      if (!isEqual(selectedOptions, optionsSelectedFlat)) {
        setSelectedOptions(optionsSelectedFlat);
      }
    }
  }, [initialSelectedGroupIds, options]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {label && (
        <div className="flex justify-between">
          <label htmlFor="groups-select-input">{label}</label>
          <samp className="text-sm relative top-1">
            {selectedOptions?.length || 0}/{optionsCount}
          </samp>
        </div>
      )}

      <MultiSelect
        ref={ref}
        options={options}
        value={selectedOptions ?? []}
        onChange={setSelectedOptions}
        disabled={disabled}
        inputId="groups-select-input"
        placeholderLoading={t('inputs.GroupsSelectInput.placeholder.loading')}
        placeholderLoaded={t('inputs.GroupsSelectInput.placeholder.loaded')}
        allItemsLabel={t('inputs.helpers.MultiSelect.allGroups')}
        allPossibleItemsLabel={t('inputs.helpers.MultiSelect.allPossibleGroups')}
        showSelectAllOption={false}
        {...props}
      />
    </>
  );
};

export const GroupsSelectInput = React.forwardRef<any, GroupsSelectInputProps>(
  GroupsSelectInputInner,
);
