import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { getQueryKeySensorAlarmRuleEnabled } from 'utils/types/Sensor';

export const useSensorAlarmRuleIsEnabled = (sensorId?: string, alarmRuleId?: string) => {
  // Check if is enabled
  const {
    data: isEnabled,
    isPending,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: getQueryKeySensorAlarmRuleEnabled(sensorId!, alarmRuleId!),
    queryFn: () => api.get(`/sensors/${sensorId}/alarm_rules/${alarmRuleId}/enabled`),
    select: (enabled: boolean) => enabled,
    enabled: !!sensorId && !!alarmRuleId,
  });

  return {
    isEnabled,
    isPending: isPending || isFetching || isLoading,
  };
};
