// NOTE: This is not implemented in the backend yet
export enum RiskScoreState {
  RISK_SCORE_VERY_LOW = 'RISK_SCORE_VERY_LOW',
  RISK_SCORE_LOW = 'RISK_SCORE_LOW',
  RISK_SCORE_MODERATE = 'RISK_SCORE_MODERATE',
  RISK_SCORE_HIGH = 'RISK_SCORE_HIGH',
  RISK_SCORE_VERY_HIGH = 'RISK_SCORE_VERY_HIGH',
  NO_RISK = 'RISK_SCORE_NO_RISK',
  UNKNOWN = 'UNKNOWN',
}
