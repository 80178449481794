import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components/Checkbox';
import { SensorsAlertRulesMatrixContext } from 'components/SensorsAlertRulesMatrix/components/SensorsAlertRulesMatrixContext';
import { useSensorAlarmRules } from 'utils/hooks/data';
import AlarmRule from 'utils/types/AlarmRule';
import Sensor from 'utils/types/Sensor';

export const SensorAlertRuleColumn: React.FC<{
  rowIndex: number;
  columnIndex: number;
  sensor: Sensor;
  alertRule: AlarmRule;
}> = ({ rowIndex, columnIndex, sensor, alertRule }) => {
  const { t } = useTranslation();

  const { enableAlarmRuleById, disableAlarmRuleById, isMutating } = useSensorAlarmRules(sensor.id, {
    enableGet: false,
  });

  const {
    setHoveringColumnIndex,
    hoveringColumnIndex,
    isAlertRuleEnabledForSensor,
    setIsMutating,
    isPending,
  } = useContext(SensorsAlertRulesMatrixContext);

  const onCheckboxChange = async (checked: boolean) => {
    setIsMutating(true);
    try {
      if (checked) {
        await enableAlarmRuleById(alertRule.id);
      } else {
        await disableAlarmRuleById(alertRule.id);
      }
    } catch (error) {
      console.error(error);
    }
    setIsMutating(false);
  };

  const isEnabled = isAlertRuleEnabledForSensor(alertRule.id, sensor.id);

  return (
    <div
      className={classNames('w-6 h-6 text-center py-1', {
        'opacity-20': isPending,
        'cursor-not-allowed': isPending,
        'bg-brand-lime': hoveringColumnIndex === columnIndex,
      })}
      onMouseEnter={() => setHoveringColumnIndex(columnIndex)}
    >
      <Checkbox
        className="block mx-auto"
        checked={isEnabled}
        onChange={async ({ target: { checked } }) => await onCheckboxChange(checked)}
        disabled={isPending || isMutating}
        onMouseEnter={() => setHoveringColumnIndex(columnIndex)}
        data-tooltip-id="route-tooltip"
        data-tooltip-content={
          isEnabled
            ? `${capitalize(t('utils:disable'))} '${alertRule.name}' ${t('utils:for')} '${sensor.name}'`
            : `${capitalize(t('utils:enable'))} '${alertRule.name}' ${t('utils:for')} '${sensor.name}'`
        }
      />
    </div>
  );
};
