import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import User from 'utils/types/User';

export const AssignedTo = ({ user, showSeparator }: { user?: User; showSeparator: boolean }) => {
  const { t } = useTranslation('components');

  return (
    <>
      {user?.full_name && (
        <span
          className={classNames('text-brand-gray-light-1 text-xs', {
            'border-l-[2px] border-brand-gray-light-2 pl-2': showSeparator,
          })}
        >
          {`${t('components:Alerts.AlertActionsStatus.assignedTo')} ${user?.full_name}`}
        </span>
      )}
    </>
  );
};
